<template>
  <div class="capitalInfo">
    <base-header :leftTit="routerTit"/>
    <div class="capitalFlow">
      <el-tabs v-model="activeTabName" @tab-click="handleClick">
        <el-tab-pane label="我要入金" name="deposit"></el-tab-pane>
        <el-tab-pane label="我要出金" name="withdraw"></el-tab-pane>
        <deposit v-if="activeTabName == 'deposit'"  />
        <withdraw v-if="activeTabName == 'withdraw'" />
      </el-tabs>
    </div>
  </div>
</template>
<script>
import BaseHeader from '../component/baseHeader'
import deposit from "./deposit";
import withdraw from "./withdraw";
export default {
  data() {
    return {
      activeTabName: "deposit",
      routerTit:'出入金管理'
    };
  },
  computed: {
  },
  components: {
    BaseHeader,
    deposit,
    withdraw
  },
  mounted() {
  },
  methods: {
    handleClick(tab) {
      this.activeTabName = tab.name;
      this.totalPage = null;
      if (this.activeTabName == 'deposit') {
      } else if (this.activeTabName == 'withdraw') {
      } 
    }
  }
};
</script>
<style lang="scss" scoped>
.capitalInfo {
  // padding: 0 15px;
  background: #F2F3F8; 
  width: 100%;
  height: 100%;
  padding-top: 20px;
}
.el-tabs__nav-wrap::after{
  background-color: #fff!important;
}

.capitalFlow {
  clear: both;
  // margin-top: 15px;

  width: 1000px;
  margin: 0 auto;
  // border: 1px solid #e6ecf2;
  background-color:#fff;
  border-radius: 2px;
  padding: 10px 40px 20px;
  border-radius: 8px;
  // overflow: hidden;
}
/deep/ .el-tabs__item.is-active{
  color: #4774FF;
}
/deep/ .el-tabs__active-bar{
  background-color: #4774FF;
}

</style>

